// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("slick-carousel/slick/slick.min")
require("modaal")

window.$ = $
window.jQuery = $

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener('DOMContentLoaded', () => {
  $('ul#main-slide').slick({
    arrows: false,
    dots: true,
    autoplay: true
  });
  $('ul#sub-slide').slick({
    arrows: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true
  });
  $('ul#sp-sub-slide').slick({
    arrows: false,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 2
  });
  $('ul.item-slide').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  });
  $('ul.coordinate-slide').slick({
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
  });
  $('dt#coordinate-detail-slide').slick({
    arrows: false,
    dots: true,
  });
  $('.inline').modaal({
    content_source: '#inline',
    overlay_opacity: 0.6,
  });
  setTimeout(function() {
    $('.inline').modaal('open')
  }, 1000);
});
